window.appendFlash = (message, alertClass = "secondary") =>
  $("#flash").append($("<div>")
    .addClass("alert alert-#{alertClass}")
    .text(message))

window.appendAlert = (message) =>
  appendFlash message, "danger"

window.appendNotice = (message) =>
  appendFlash message, "secondary"

window.clearFlash = =>
  $("#flash").empty()
