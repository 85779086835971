// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();

require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

/** JS */

window.$ = require("jquery/dist/jquery");

import "bootstrap/dist/js/bootstrap.bundle";
import "font-awesome/css/font-awesome.css";
import "font-awesome/fonts/fontawesome-webfont.eot";
import "font-awesome/fonts/fontawesome-webfont.svg";
import "font-awesome/fonts/fontawesome-webfont.ttf";
import "font-awesome/fonts/fontawesome-webfont.woff";
import "font-awesome/fonts/fontawesome-webfont.woff2";
import "font-awesome/fonts/FontAwesome.otf";

import "./app"

/** CSS */

// TODO: Make work by including in pack file
import "bootstrap-datepicker/dist/js/bootstrap-datepicker";

import "bootstrap/dist/css/bootstrap.css";
import "dropzone/dist/dropzone.css";
import "material-icons/iconfont/material-icons.css";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
